import React from "react";
import {useEffect} from "react";
import styled from "styled-components";
import {LAPTOP_MOBILE_POINTER} from "@tools/constants";

export const PartnerQuizSection: React.FC<{}> = ({}) => {

    useEffect(() => {
        const mainUrl = "/promo/iframe-quiz/partner";
        const urlParams = window.location.search;
        const iframeUrl = mainUrl + urlParams;
        const quizFrame: any = document.getElementById('quiz');
        quizFrame.src = iframeUrl;
        quizFrame.addEventListener('load', ()=>{
            try {
                quizFrame.style.height = quizFrame.contentWindow.document.documentElement.scrollHeight + 'px';
            } catch (e) {
                quizFrame.style.height = '510px';
            }
        })
        window.addEventListener('resize', ()=>{
            try {
                quizFrame.style.height = quizFrame.contentWindow.document.documentElement.scrollHeight + 'px';
            } catch (e) {
                quizFrame.style.height = '510px';
            }
        })
    }, [])

    return <SectionQuiz id="quizSection">
        <iframe style={{width: '100%', border: 'unset', minWidth: '100vw'}} id="quiz"></iframe>
    </SectionQuiz>
}

const SectionQuiz = styled.section`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;
  align-items: center;
  position: relative;
  @media (max-width: ${LAPTOP_MOBILE_POINTER}px) {
    padding-bottom: 20px;
  }
  @media (min-width: ${LAPTOP_MOBILE_POINTER + 1}px) {
    padding-bottom: 20px;
  }
`;

